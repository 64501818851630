import React from "react";
import { Container, Text } from "@atoms";

const Copy = ({ copy }) => {
  return (
    <Container variant="xxs">
      <Text variant="body" richText>
        {copy}
      </Text>
    </Container>
  );
};

export default Copy;
